const asyncImport = require('util/asyncImport').default;

export const stuttering = Object.freeze({
	NONE: 'none',
	THROTTLE: 'throttle',
	DEBOUNCE: 'debounce',
});
export default async function onWidthResize(
	onResize,
	stutteringMode,
	delay,
	onlyReturnListener,
) {
	const stutters = {};
	if (stutteringMode !== stuttering.NONE)
		await asyncImport(
			stutters,
			stutteringMode === stuttering.THROTTLE ? 'throttle' : 'debounce',
		);
	let currentWidth = window.innerWidth;
	const event = () => {
		if (window.innerWidth !== currentWidth) {
			onResize();
			currentWidth = window.innerWidth;
		}
	};
	let listener = event;
	switch (stutteringMode) {
		case stuttering.THROTTLE:
			listener = stutters.throttle(event, delay);
			break;
		case stuttering.DEBOUNCE:
			listener = stutters.debounce(event, delay);
			break;
		default:
			break;
	}
	if (onlyReturnListener) return listener;
	else window.addEventListener('resize', listener);
}
