const {
	fillWithDomElements,
	onControllerReady,
	initControllerLink,
	trackGtm,
} = require('util/functions');

const CLASS_DROP_PARTIAL = 'search-field-drop';
const CLASS_LOADING_OVERLAY = 'loading-overlay';
const CLASS_LINK_ALL = 'market-search-menu--all-market';

const CLASS_ACTIVE = 'active';

const selectors = {
	dropPartial: CLASS_DROP_PARTIAL,
	loadingOverlay: CLASS_LOADING_OVERLAY,
	linkAll: CLASS_LINK_ALL, // used outside (ex: world controller)
};

let countryList = null;
let noCurrent = false;
const fetchCountries = async countriesApi => {
	if (countryList) return countryList;
	const response = await fetch(countriesApi).catch(console.error);
	if (response.ok) {
		const { countries, current } = await response.json();
		if (current > 0) {
			const currentCountry = countries.splice(current, 1)[0];
			countryList = [currentCountry, ...countries];
		} else countryList = countries;
		if (current === -1) noCurrent = true;
		return countryList;
	}
};

module.exports = {
	async init() {
		fillWithDomElements(this, selectors);
		this.countriesApi = this.root.dataset.countriesApi;
		this.dropController = await onControllerReady(this.dropPartial);
		this.dropController.onListClick = country =>
			trackGtm({
				eventCategory: 'market-navigation',
				eventAction: 'click',
				eventLabel: country,
			});
		initControllerLink(this);
	},
	async fetchCountries() {
		this.loadingOverlay.classList.add(CLASS_ACTIVE);
		const countries = await fetchCountries(this.countriesApi);
		this.renderCountries(countries);
		this.onCountryLoad && this.onCountryLoad();
		this.loadingOverlay.classList.remove(CLASS_ACTIVE);
		this.loaded = true;
	},
	renderCountries(countries) {
		let updateCountries = {};

		const updateArrayURL = item => {
			return {
				name: item.name,
				url: item.url + sessionStorage.getItem('params'),
			};
		};

		if (sessionStorage.getItem('params')) {
			updateCountries = countries.map(updateArrayURL);
		} else {
			updateCountries = countries;
		}

		this.dropController.suggestionsList = updateCountries.map((country, i) => ({
			...country,
			...(i === 0 && !noCurrent ? { highlighted: true } : {}),
		}));

		this.dropController.updateSuggestions(true);
	},
};
