const {
	default: { breakNavigation },
} = require('util/sass-variables');
const { stuttering, default: onWidthResize } = require('util/onWidthResize');
const {
	mediaQueryMin,
	onControllerReady,
	toggleMenuArias,
	freezeScroll,
	unfreezeScroll,
} = require('util/functions');
const { emitter, DELAY_DEBOUNCE, EVENT_MAIN_NAV_OPEN } = require('util/consts');
const {
	outClickSubscribe,
	outClickUnSubscribe,
} = require('util/outClickSubscriber');

module.exports = {
	async init() {
		this.glass = this.root.getElementsByClassName('search--glass')[0];
		this.field = this.root.querySelector('.search-field-drop--field');
		this.fieldInput = this.field.querySelector('input');
		const search = this.root.querySelector('.search-field-drop');
		this.uuid = this.root.dataset.uuid;
		this.opened = false;
		onWidthResize(this.close.bind(this), stuttering.DEBOUNCE, DELAY_DEBOUNCE);

		this.glass.addEventListener('click', e => {
			e.stopPropagation();
			if (this.input.value && this.opened) {
				this.redirect();
			} else {
				if (this.opened) this.close();
				else this.open();
			}
		});
		this.glass.addEventListener('focus', () => {
			if (mediaQueryMin(breakNavigation)) this.open();
		});
		this.searchController = await onControllerReady(search);
		this.input = this.searchController.input;
		[
			[
				'onOpen',
				() => {
					this.field.classList.add('dropped');
				},
			],
			[
				'onClose',
				() => {
					this.field.classList.remove('dropped');
				},
			],
			[
				'onEnter',
				() => {
					this.redirect();
				},
			],
		].forEach(([event, fn]) => (this.searchController[event] = fn));
		toggleMenuArias(this.glass, [this.fieldInput], false);
		this.searchController?.closeBtn?.addEventListener(
			'click',
			this.close.bind(this),
		);
	},
	redirect() {
		this.searchController.redirectTo(this.input.value);
	},

	open() {
		if (!this.opened) {
			this.root.classList.add('open');
			this.field.focus();
			if (this.searchController) this.searchController.focus();
			this.opened = true;
			if (!mediaQueryMin(breakNavigation)) {
				emitter.emit(EVENT_MAIN_NAV_OPEN, {
					closeCallback: this.close.bind(this),
					target: `${this.uuid}-search`,
					label: this.glass.getAttribute('aria-label'),
				});
			}
			outClickSubscribe(this.root, this.close.bind(this));
			toggleMenuArias(this.glass, [this.fieldInput], true);
			freezeScroll(true);
		}
	},
	close() {
		if (this.opened) {
			this.root.classList.remove('open');
			if (this.searchController) this.searchController.close();
			this.field.blur();
			this.opened = false;
			outClickUnSubscribe(this.root);
			toggleMenuArias(this.glass, [this.fieldInput], false);
			this.fieldInput.value = '';
			unfreezeScroll();
		}
	},
};
