const { MAIN_EASING } = require('../../../../js/consts');
const {
	default: { breakMedium, slowDelay },
} = require('../../../../js/sass-variables');
const {
	getFocusablesFrom,
	mediaQueryMin,
	getNumber,
	awaitEventOn,
} = require('../../../../js/functions');
const asyncImport = require('util/asyncImport').default;

const SELECTOR_MAIN_NAV = '.nav-layout';

const baseDelay = getNumber(slowDelay) * 1000;

module.exports = {
	async init() {
		await asyncImport(this, ['debounce', 'anime']);

		this.scrollEvent = this.debounce(this.onScroll.bind(this), 100);
		window.addEventListener(
			'resize',
			this.debounce(this.onResize.bind(this), 100),
		);
		this.root.addEventListener('click', this.doScroll.bind(this));
		this.onScroll();
		this.onResize();
	},
	onResize() {
		if (mediaQueryMin(breakMedium))
			window.removeEventListener('scroll', this.scrollEvent);
		else window.addEventListener('scroll', this.scrollEvent);
	},
	onScroll() {
		if (window.pageYOffset > window.innerHeight) this.activate();
		else this.deactivate();
	},
	async activate() {
		this.root.classList.add('active');
	},
	async deactivate() {
		this.root.classList.remove('active');
		await awaitEventOn(this.root, 'transitionend');
	},
	doScroll() {
		this.deactivate();
		this.root.removeEventListener('scroll', this.scrollEvent);
		let duration = (window.pageYOffset / window.innerHeight) * baseDelay;
		if (duration > 1000) duration = 1000;
		this.animation = this.anime({
			targets: document.scrollingElement,
			scrollTop: 0,
			easing: MAIN_EASING,
			duration,
		}).finished.then(() => {
			const firstElement = getFocusablesFrom(
				document.querySelector(SELECTOR_MAIN_NAV),
			)[0];
			firstElement.focus();

			this.root.addEventListener('scroll', this.scrollEvent);
		});
	},
};
