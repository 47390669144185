const {
	fillWithDomElements,
	fillWithDomElementsAll,
	toggleMenuArias,
} = require('util/functions');
const {
	outClickUnSubscribe,
	outClickSubscribe,
} = require('util/outClickSubscriber');

const CLASS_OPEN = 'open';
const CLASS_FOCUSED = 'focused';

const PROPERTY_HEIGHT = '--drop-height';

const selectors = {
	content: 'drop-down--content',
	list: 'drop-down--list',
	arrow: 'drop-down--arrow',
	button: 'drop-down--button',
};
const selectorsAll = {
	links: 'drop-down--link',
};

module.exports = {
	init() {
		fillWithDomElements(this, selectors);
		fillWithDomElementsAll(this, selectorsAll);
		this.endCloseAnimationBinded = this.endCloseAnimation.bind(this);
		this.isOpen = false;
		this.initEvents();
	},
	initEvents() {
		this.button.addEventListener('click', this.toggleDropdown.bind(this));
		this.root.addEventListener('mouseenter', this.open.bind(this));
		this.root.addEventListener('mouseleave', this.close.bind(this));
		this.button.addEventListener('focus', () =>
			this.root.classList.add(CLASS_FOCUSED),
		);
		this.button.addEventListener('blur', () =>
			this.root.classList.remove(CLASS_FOCUSED),
		);
	},
	open() {
		if (this.isOpen) return;
		this.isOpen = true;
		this.arrow.classList.add(CLASS_OPEN);
		this.root.classList.add(CLASS_OPEN);
		const height = this.content.offsetHeight + 20;
		this.root.style.setProperty(PROPERTY_HEIGHT, height + 'px');
		this.setAriaState();
		outClickSubscribe(this.root, this.close.bind(this));
	},

	close() {
		if (!this.isOpen) return;
		this.isOpen = false;
		this.root.style.setProperty(PROPERTY_HEIGHT, '0px');
		this.root.addEventListener('transitionend', this.endCloseAnimationBinded);
		this.setAriaState();
		outClickUnSubscribe(this.root);
	},
	endCloseAnimation() {
		this.arrow.classList.remove(CLASS_OPEN);
		this.root.classList.remove(CLASS_OPEN);
		this.root.removeEventListener(
			'transitionend',
			this.endCloseAnimationBinded,
		);
	},
	setAriaState() {
		toggleMenuArias(this.button, this.links, this.isOpen);
	},
	toggleDropdown() {
		if (this.isOpen) {
			this.close();
		} else {
			this.open();
		}
	},
};
