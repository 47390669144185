const { MAIN_EASING } = require('../../../../js/consts');
const {
	default: { fastDelay, breakMediumSmall },
} = require('../../../../js/sass-variables');
const {
	getNumber,
	mediaQueryMin,
	initControllerLink,
	fillWithDomElements,
	addEventsTo,
	trackGtm4,
} = require('util/functions');
const asyncImport = require('util/asyncImport').default;

const duration = getNumber(fastDelay) * 1000;

const selectors = {
	suggestions: 'search-field-drop--suggestions',
	backSuggestion: 'search-field-drop--back-suggestion',
	drop: 'search-field-drop--drop',
	closeBtn: 'search-field-drop--close',
};

module.exports = {
	async init() {
		await asyncImport(this, ['anime', 'debounce']);
		this.initElements();
		this.initEvents();

		// values
		this.searchUrl = this.root.dataset.searchurl;
		this.searchIndex = this.root.dataset.index;
		this.fetchOnce = !!this.root.dataset.fetchOnce;
		this.noFetch = !!this.root.dataset.noFetch;
		this.alwaysOpen = !!this.root.dataset.alwaysOpen;
		this.showAllByDefault = !!this.root.dataset.showAllByDefault;
		this.categories = [];
		this.suggestionsList = [];
		this.opened = this.alwaysOpen;
		this.suggestionsCursor = null;
		this.inputEvent();
		if (this.fetchOnce && !this.noFetch) this.fetchSuggestions(true);
		initControllerLink(this);
	},
	clear() {
		this.input.value = '';
	},
	initElements() {
		this.input = this.root.querySelector('input');
		this.button = this.root.querySelector('.search-field-drop--field button');
		fillWithDomElements(this, selectors);
	},
	initEvents() {
		const debouncedInput = this.debounce(
			this.debouncedInputEvent.bind(this),
			100,
		);
		addEventsTo(this.input, {
			click: e => {
				e.stopPropagation();
			},
			blur: this.close.bind(this),
			input: () => {
				this.inputEvent.bind(this);
				debouncedInput();
			},
			keydown: this.inputKeyEvent.bind(this),
		});
		this.suggestions.addEventListener(
			'click',
			this.listClickHandler.bind(this),
		);
		if (this.button)
			this.button.addEventListener('click', this.buttonClickHandler.bind(this));
	},
	buttonClickHandler() {
		if (this.input.value !== '') {
			this.redirectTo(this.input.value);
		}
	},
	listClickHandler(e) {
		const { target } = e;
		const { nodeName } = target;
		const isButton = nodeName === 'BUTTON';
		const isLink = nodeName === 'A';
		if (isButton || isLink) {
			e.stopPropagation();
			if (isButton) {
				const { value } = target.dataset;
				this.selectedName = value;
				this.input.value = this.selectedName;
				if (isButton) this.redirectTo(this.selectedName);
			}
			if (this.onListClick) this.onListClick(target.innerText);
			this.close();
		}
		trackGtm4({
			event: 'GA4_event_c_main_navigation',
			widget_name: 'V3-Drop-down-menu',
			link_url: target.href,
			link_text: target.innerText,
			level: 'Level 2',
		});
	},
	inputEvent() {
		if (!this.alwaysOpen)
			if (this.input.value.length > 0) {
				if (mediaQueryMin(breakMediumSmall)) {
					if (this.button) {
						this.button.style.opacity = null;
						this.button.style.cursor = null;
					}
				}
			} else {
				if (this.button) {
					this.button.style.opacity = 0.5;
					this.button.style.cursor = 'default';
				}
			}
		this.updateSuggestions();
		if (this.onUpdate) this.onUpdate(this.input.value);
	},
	debouncedInputEvent() {
		const input = this.input.value;
		if (input.length > 2 || this.opened) {
			if (!this.alwaysOpen) this.suggestions.style.opacity = 0.7;
			if (!this.fetchOnce && !this.noFetch) this.fetchSuggestions();
			else this.updateSuggestions();
		}
	},
	enterEvent() {
		// in case the list is post filtered, lets use that one
		const list = this.filteredList || this.suggestionsList;
		const suggestion = list[this.suggestionsCursor];
		if (suggestion?.url) {
			const { url } = list[this.suggestionsCursor];
			if (url) window.location = url;
			else this.redirectTo(this.input.value);
		}
		this.close();
		if (this.onEnter) {
			this.onEnter();
		} else {
			this.buttonClickHandler();
		}
	},
	inputKeyEvent({ key }) {
		if (key === 'Enter') {
			this.enterEvent();
		} else if (
			key === 'ArrowRight' &&
			this.backSuggestion.innerText.length &&
			this.suggestionsCursor === null
		) {
			if (this.backSuggestion.innerText.length > this.input.value.length) {
				this.input.value = this.backSuggestion.innerText;
			}
			this.close();
			if (this.onUpdate) this.onUpdate(this.input.value);
		} else if (key === 'ArrowUp' && this.suggestionsList.length) {
			this.moveCursor(false);
		} else if (key === 'ArrowDown' && this.suggestionsList.length) {
			this.moveCursor(true);
		} else if (key === 'Escape') {
			if (this.input.value === '') {
				this.input.blur();
			}
			if (this.suggestionsCursor !== null) {
				this.input.value = this.inputEnteredValue;
			}
			this.suggestionsCursor = null;
			this.close();
		}
	},
	getUrl(url) {
		if (url.slice(0, 4) === 'http') return url;
		else return `${window.location.protocol}//${window.location.host}${url}`;
	},
	getField() {
		return this.input.value || this.input.attributes.placeholder.value;
	},
	redirectTo(search) {
		if (search.length > 0 && this.root.dataset.redirectionurl) {
			const url = this.getUrl(this.root.dataset.redirectionurl);
			let list = '';
			this.categories.forEach((category, i) => {
				list += category + (i !== this.categories.length - 1 ? ',' : '');
			});
			const request = `${url}?indexCatalogue=${
				this.searchIndex
			}&searchQuery=${encodeURIComponent(
				search,
			)}&CategoryList=${encodeURIComponent(list)}`;
			const tags = document.querySelector('.search-results-page--tags');
			let tagValues = [];
			tags?.querySelectorAll('button').forEach(element => {
				tagValues.push(element.textContent);
			});

			trackGtm4({
				event: 'GA4_event_c_search',
				search_term: search.replace(/ /g, '').length > 0 ? search : 'none',
				search_location: this.root.dataset.dropdown
					? 'top menu'
					: list
					? 'search page'
					: 'page',
				search_filter: list ? tagValues.join('|') : 'none',
				widget_name: 'V3-Search-box',
			});

			window.location.href = request;
		}
	},
	fetchSuggestions(init) {
		const input = this.input.value;
		const url = this.getUrl(this.searchUrl);
		const request = this.fetchOnce
			? url
			: `${url}?IndexName=${this.searchIndex}&SuggestionFields=Title&Text=${input}`;
		if (input.length || this.fetchOnce) {
			window
				.fetch(request, {
					method: 'GET',
				})
				.then(answer => {
					answer.json().then(result => {
						this.suggestionsList = result.Suggestions
							? result.Suggestions
							: result;
						this.updateSuggestions(init);
						this.suggestions.style.opacity = 1;
					});
				})
				.catch(error => console.error(error));
		} else {
			this.updateSuggestions();
		}
	},
	setCategories(list) {
		this.categories = list;
	},
	updateSuggestions(init) {
		const input = init ? '' : this.input.value;
		const [content, count, backSuggestion] = this.getSuggestions(input);
		if (count >= 1) {
			this.suggestions.innerHTML = content;
			this.backSuggestion.innerHTML = backSuggestion;
			if (!this.opened) this.open();
			else this.resizeDrop();
		} else {
			this.suggestions.innerHTML = '';
			this.backSuggestion.innerHTML = '';
			this.close();
		}
	},
	filterSuggestions(suggestions, input) {
		return suggestions.filter(suggestion => {
			const name =
				typeof suggestion === 'string' ? suggestions : suggestion.name;
			return name.toLowerCase().includes(input.toLowerCase());
		});
	},
	generateBackSuggestion(name, input) {
		return `<span class="blank">${input.replace(
			/ /g,
			'&nbsp;',
		)}</span><span>${name.substring(input.length)}</span>`;
	},
	getSuggestions(input) {
		let content = '';
		let count = 0;
		let backSuggestion = '';
		if (input || this.showAllByDefault) {
			const regExp = new RegExp(input, 'gi');
			const list =
				this.showAllByDefault && input
					? this.filterSuggestions(this.suggestionsList, input)
					: this.suggestionsList;
			// in case the search is provided the result of the filtering shall be memoized
			if (this.showAllByDefault) this.filteredList = list;
			list.forEach(value => {
				// warning: this.showAllByDefault being true assumes that the value isn't string and is an object
				// with name, url, highlighted, but that case is only met when filling the content by js.
				const { name, url, highlighted } =
					typeof value === 'string'
						? {
								name: value,
						  }
						: value;
				if (
					backSuggestion === '' &&
					input.length &&
					name.toLowerCase().indexOf(input.toLowerCase()) === 0
				) {
					backSuggestion = this.generateBackSuggestion(name, input);
				}
				const label = input
					? name.replace(regExp, occurrence => `<strong>${occurrence}</strong>`)
					: name;

				if (this.showAllByDefault || label.indexOf('<strong>') !== -1) {
					const highlight = highlighted ? ' class="highlighted"' : '';
					content += url
						? `<li><a tabindex="-1" href="${url}"${highlight}>${label}</a></li>`
						: `<li><button tabindex="-1" data-value="${name}"${highlight}>${label}</button></li>`;
					count++;
				}
			});
		}
		return [content, count, backSuggestion];
	},
	moveCursor(way) {
		let current = null;
		if (this.suggestionsCursor !== null) {
			current = this.suggestions.childNodes[this.suggestionsCursor];
		} else {
			this.inputEnteredValue = this.input.value;
		}
		if (this.suggestionsCursor === null) this.suggestionsCursor = -1;
		this.suggestionsCursor += way ? 1 : -1;
		if (this.suggestionsCursor < 0)
			this.suggestionsCursor = this.suggestionsList.length - 1;
		if (this.suggestionsCursor > this.suggestionsList.length - 1)
			this.suggestionsCursor = 0;
		const next = this.suggestions.childNodes[this.suggestionsCursor];
		if (current) current.classList.remove('active');
		next?.classList.add('active');
		this.input.value = next.innerText;
		this.backSuggestion.innerHTML = '';
		if (this.onUpdate) this.onUpdate(this.input.value);
	},
	focus() {
		this.input.focus();
	},
	open() {
		if (!this.opened) {
			this.opened = true;
			this.root.classList.add('open');
			if (this.onOpen) this.onOpen();
			this.resizeDrop();
		}
	},
	resizeDrop() {
		if (!this.alwaysOpen)
			this.anime({
				targets: this.drop,
				height: this.suggestions.clientHeight,
				easing: MAIN_EASING,
				duration,
			});
	},
	close() {
		if (this.opened && !this.alwaysOpen) {
			this.opened = false;
			this.suggestionsCursor = null;
			this.root.classList.remove('open');
			if (this.onClose) this.onClose();
			this.backSuggestion.innerText = '';
			if (!this.fetchOnce || !this.noFetch) this.suggestionsList = [];
			this.anime({
				targets: this.drop,
				height: 0,
				easing: MAIN_EASING,
				duration,
			});
		}
	},
};
