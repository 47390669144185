const CLASS_INACTIVE = 'inactive';

function setLabelEvents(label, form) {
	const ref = label.getAttribute('for');
	const input = form.querySelector(`input#${ref},textarea#${ref}`);
	if (input) {
		const handler = e => {
			const value = input.value;
			if (value) label.classList.add(CLASS_INACTIVE);
			else if (!e) label.classList.remove(CLASS_INACTIVE);
		};
		input.addEventListener('input', handler);
		input.addEventListener('blur', () => {
			handler();
		});
		input.addEventListener('focus', () => {
			label.classList.add(CLASS_INACTIVE);
		});
		handler();
	}
}
export default () => {
	const forms = document.querySelectorAll('[data-sf-role="form-container"]');
	if (forms) {
		forms.forEach(form => {
			const labels = form.querySelectorAll('label');
			labels.forEach(label => {
				setLabelEvents(label, form);
			});
		});
	}
};
