const { trackGtm, trackGtm4 } = require('util/functions');
const { default: UnfoldableList } = require('util/UnfoldableSet');

module.exports = {
	init() {
		const subMenuTitles = this.root.querySelectorAll('.unfoldable-menu--title');
		const subMenuItems = this.root.querySelectorAll('.unfoldable-menu--link');
		const eventType =
			window.location.href.indexOf('markets') > -1
				? 'GA4_event_c_market_sub_navigation'
				: 'GA4_event_c_sub_navigation';

		subMenuTitles.forEach(element => {
			element.addEventListener('click', () => {
				const url = element.hasAttribute('href')
					? element.href
					: element.parentNode.href;
				trackGtm({
					eventCategory: 'market-sub-navigation',
					eventAction: 'click',
					eventLabel: element.innerText,
				});
				if (element.parentNode.tagName != 'BUTTON') {
					trackGtm4({
						event: eventType,
						widget_name: 'V3-Sub-navigation',
						link_url: url,
						link_text: element.innerText,
						level: 'Level 1',
					});
				}
			});
		});
		subMenuItems.forEach(element => {
			element.addEventListener('click', () => {
				const subMenuContainer = element.closest(
					'.unfoldable-menu--container',
				).previousSibling;
				trackGtm({
					eventCategory: 'market-sub-navigation',
					eventAction: subMenuContainer.previousSibling.innerText,
					eventLabel: element.innerText,
				});
				trackGtm4({
					event: eventType,
					widget_name: 'V3-Sub-navigation',
					link_url: element.href,
					link_text: element.innerText,
					level: 'Level 2',
				});
			});
		});

		new UnfoldableList(
			this.root,
			{
				unfoldButton: 'sub-nav--unfold',
				container: 'sub-nav--menus-container',
				menus: 'sub-nav--menus',
				noScroll: true,
			},
			({ target: { innerText } }) => {
				trackGtm({
					eventCategory: 'sub-navigation',
					eventAction: 'click',
					eventLabel: innerText,
				});
			},
		);
	},
};
