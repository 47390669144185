const asyncImport = (self, libs) =>
	new Promise((resolve, catchFn) => {
		const list = typeof libs === 'string' ? [libs] : libs;
		let remaining = list.length;
		const gotLoaded = async (libName, libPromise, options) => {
			const { noDefault, getSub } = options || {};
			const result = await libPromise;
			const lib = getSub ? result.default[getSub] : result;
			remaining--;
			self[libName] = noDefault || getSub ? lib : lib.default;
			if (remaining === 0) resolve();
		};
		list.forEach(lib => {
			switch (lib) {
				case 'debounce':
					gotLoaded(
						'debounce',
						import(
							/* webpackChunkName: "debounce-throttle" */ './debounce-throttle'
						).catch(catchFn),
						{ getSub: 'debounce' },
					);
					break;
				case 'throttle':
					gotLoaded(
						'throttle',
						import(
							/* webpackChunkName: "debounce-throttle" */ './debounce-throttle'
						).catch(catchFn),
						{ getSub: 'throttle' },
					);
					break;
				case 'anime':
					gotLoaded(
						'anime',
						import(/* webpackChunkName: "animejs" */ 'animejs').catch(catchFn),
					);
					break;
				case 'Plyr':
					gotLoaded(
						'Plyr',
						import(/* webpackChunkName: "plyr" */ 'Plyr').catch(catchFn),
					);
					break;
				case 'pikaday':
					gotLoaded(
						'pikaday',
						import(/* webpackChunkName: "pikaday" */ 'pikaday').catch(catchFn),
					);
					break;
				case 'Hammer':
					gotLoaded(
						'Hammer',
						import(/* webpackChunkName: "hammerjs" */ 'hammerjs').catch(
							catchFn,
						),
					);
					break;
				case 'Sticky':
					gotLoaded(
						'Sticky',
						import(/* webpackChunkName: "sticky-js" */ 'sticky-js').catch(
							catchFn,
						),
					);
					break;
				case 'TinySlider':
					gotLoaded(
						'TinySlider',
						import(
							/* webpackChunkName: "tiny-slider" */ '../../node_modules/tiny-slider/src/tiny-slider'
						).catch(catchFn),
						{ noDefault: true },
					);
					break;
				case 'gsap':
					gotLoaded(
						'gsap',
						import(/* webpackChunkName: "gsap" */ './gsap/gsap.min').catch(
							catchFn,
						),
					);
					break;
				case 'ScrollTrigger':
					gotLoaded(
						'ScrollTrigger',
						import(
							/* webpackChunkName: "ScrollTrigger" */ './gsap/ScrollTrigger.min'
						).catch(catchFn),
					);
					break;
				default:
					const error = `no such lib as ${lib}`;
					console.error(error);
					catchFn(error);
			}
		});
	});

export default asyncImport;
