const {
	default: { breakNavigation },
} = require('util/sass-variables');
const {
	mediaQueryMin,
	setHeightFrom,
	addEventsTo,
	toggleMenuArias,
	fillWithDomElements,
	fillWithDomElementsAll,
} = require('util/functions');
const { stuttering, default: onWidthResize } = require('util/onWidthResize');
const { triggerOtherClose } = require('util/outClickSubscriber');

const OPEN = true;
const CLOSE = false;

const SELECTOR_DROPPABLE = '.droppable';
const CLASS_ACTIVE = 'active';

const CLASS_SINGLE_MENU = 'unfoldable-menu';
const CLASS_LINK = 'unfoldable-menu--link';
const CLASS_MAIN_UNFOLDABLES = 'unfoldable-menu--unfold-button';
const CLASS_DROPPER = 'unfoldable-menu--dropper';
const CLASS_CONTAINER = 'unfoldable-menu--container';
const CLASS_CONTENT = 'unfoldable-menu--content';

class UnfoldableList {
	constructor(
		root,
		{ unfoldButton, container, menus, links, noScroll },
		onLinkClick,
	) {
		this.root = root;
		this.onLinkClick = onLinkClick;
		this.noScroll = noScroll;
		fillWithDomElements(this, {
			unfoldButton,
			container,
			menus,
		});
		fillWithDomElementsAll(this, {
			links: CLASS_LINK,
			singleMenus: CLASS_SINGLE_MENU,
			mainUnfoldables: CLASS_MAIN_UNFOLDABLES,
		});
		this.open = false;
		this.generateMenuList(links);
		this.initEvents();
		this.initGTMs();
		this.onResize(false);
	}
	generateMenuList(links) {
		this.menuList = [...this.singleMenus]
			.filter(menu => menu.querySelector(SELECTOR_DROPPABLE))
			.map(menu => {
				const menuData = { root: menu };
				fillWithDomElements(menuData, {
					dropper: CLASS_DROPPER,
					container: CLASS_CONTAINER,
					content: CLASS_CONTENT,
					button: CLASS_MAIN_UNFOLDABLES,
				});
				fillWithDomElementsAll(menuData, {
					links,
				});
				return menuData;
			});
	}
	initEvents() {
		this.unfoldButton.addEventListener('click', this.toggle.bind(this));
		document.addEventListener('click', () => {
			if (!this.mobile) this.closeMenu.bind(this);
		});
		this.menuList.forEach(menu => {
			const { root, button } = menu;
			addEventsTo(root, {
				mouseover: () => {
					if (!this.mobile) this.submenuSet(menu, OPEN);
				},
				mouseout: () => {
					if (!this.mobile) this.submenuSet(menu, CLOSE);
				},
			});
			button.addEventListener('click', this.submenuToggle.bind(this, menu));
		});

		onWidthResize(this.onResize.bind(this), stuttering.DEBOUNCE, 100);
	}
	initGTMs() {
		this.onLinkClick &&
			this.links.forEach(link => {
				link.addEventListener('click', this.onLinkClick);
			});
	}
	onResize(init) {
		this.mobile = !mediaQueryMin(breakNavigation);
		if (init) this.menuList.forEach(menu => this.submenuSet(menu, CLOSE));
		if (!this.mobile) {
			this.open = false;
			this.container.style.height = '';
			this.openMenu();
		} else this.closeMenu();
	}
	toggle(e) {
		if (this.mobile) {
			e.stopPropagation();
			if (this.open) this.closeMenu();
			else this.openMenu();
		}
	}
	openMenu() {
		this.open = true;
		if (this.noScroll && this.mobile) {
			this.scrollPositon = document.documentElement.scrollTop;
			document.body.classList.add('no-scroll');
			document.documentElement.style.height = '100vh';
			setTimeout(() => {
				if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
					document.body.classList.add('no-scroll-ios');
				}
			}, 500);
		}
		setHeightFrom(this.container, this.mobile ? window : this.menus);
		toggleMenuArias(this.unfoldButton, this.mainUnfoldables, true);
	}
	closeMenu() {
		if (this.noScroll) {
			if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
				document.body.classList.remove('no-scroll-ios');
			}
			document.documentElement.style.height = null;
			setTimeout(() => {
				document.body.classList.remove('no-scroll');
			}, 500);
			window.scroll({
				top: this.scrollPositon,
				left: 0,
				behavior: 'smooth',
			});
		}
		this.open = false;
		this.container.style.height = 0;
		setTimeout(() => {
			this.menus.scrollTop = 0;
		}, 500);
		toggleMenuArias(this.unfoldButton, this.mainUnfoldables, false);
	}
	submenuToggle(menu) {
		const { dropper, root } = menu;
		if (dropper.classList.contains(CLASS_ACTIVE)) this.submenuSet(menu, CLOSE);
		else {
			this.submenuSet(menu, OPEN);
			this.menuList.forEach(otherMenu => {
				if (otherMenu.root !== root) this.submenuSet(otherMenu, CLOSE);
			});
		}
	}
	submenuSet(menu, isOpen) {
		triggerOtherClose();
		const { dropper, container, content, button, links } = menu;
		[dropper, button, container].forEach(element =>
			element?.classList.toggle(CLASS_ACTIVE, isOpen),
		);
		if (isOpen) setHeightFrom(container, content);
		else container.style.height = 0;
		toggleMenuArias(button, links, isOpen);
	}
}
export default UnfoldableList;
