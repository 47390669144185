import { bodyElement } from 'util/consts';

const TAG_NAME = 'tags';
const SOURCE_NAME = 'source';
const DEFAULT_SOURCE = 'pmi.com';
const SOURCE_QUESTION = `?${SOURCE_NAME}=`;
const TAG_QUESTION = `?${TAG_NAME}=`;
const AND_SOURCE = `&${SOURCE_NAME}=`;
const AND_TAG = `&${TAG_NAME}=`;
const PMI_SOURCE = `${SOURCE_NAME}=${DEFAULT_SOURCE}&`;

const linkHasParams = param =>
	[TAG_QUESTION, SOURCE_QUESTION, AND_SOURCE, AND_TAG].some(tag =>
		param.includes(tag),
	);

const avatureTags = (() => {
	let urlParams = window.location.search;
	let paramsArray = [];

	let paramsSource;
	let paramsTags;
	let sessionItems;
	let sessionTags;

	let newParams = urlParams.split('&');

	const ifParemsDiffers =
		urlParams.includes('source') &&
		sessionStorage.getItem('params') &&
		urlParams !== sessionStorage.getItem('params');
	const ifSessionEmpty =
		!sessionStorage.getItem('params') && urlParams.includes('source');

	newParams.forEach(element => {
		if (element.includes('source')) {
			paramsSource = element;
		} else if (element.includes('tags')) {
			paramsTags = element;
		}
	});

	if (ifParemsDiffers || ifSessionEmpty) {
		if (paramsSource.includes('?')) {
			paramsSource = paramsSource.split('?')[1];
		}
		if (sessionStorage.getItem('params')) {
			sessionItems = sessionStorage.getItem('params').split('&');
			sessionItems.forEach(element => {
				if (element.includes('tags')) {
					sessionTags = element.split('tags=')[1];
				}
			});

			const newParamsTags = paramsTags.split('tags=')[1];
			const arraySessionTags = sessionTags.split('|');
			const arrayParamsTags = newParamsTags.split('|');
			const newCombinedTags = [
				...new Set([...arraySessionTags, ...arrayParamsTags]),
			].join('|');

			urlParams = `?${paramsSource}${AND_TAG}${newCombinedTags}`;
			sessionStorage.setItem('params', urlParams);
		} else {
			urlParams = `?${paramsSource}&${paramsTags}`;
			sessionStorage.setItem('params', urlParams);
		}
		paramsArray = urlParams.split(/(?=&)/g);
	} else if (sessionStorage.getItem('params')) {
		paramsArray = sessionStorage.getItem('params').split(/(?=&)/g);
	} else if (urlParams.includes('tags')) {
		const pmiSource = `${PMI_SOURCE}`;
		const tags = `${TAG_NAME}=`;

		paramsArray = urlParams
			.split('tags=')[0]
			.concat(pmiSource.concat(tags.concat(urlParams.split('tags=')[1])));
		window.location = paramsArray;
		paramsArray = paramsArray.split(/(?=&)/g);
	}

	let params = {};
	//Filter params in case there are any other params besides source/tags

	let filteredArray = paramsArray.filter(
		param =>
			param.includes(TAG_QUESTION) ||
			param.includes(SOURCE_QUESTION) ||
			param.includes(AND_SOURCE) ||
			param.includes(AND_TAG),
	);
	if (filteredArray.length === 0) {
		return null;
	}
	filteredArray.forEach(value => {
		if (value.includes(AND_TAG) || value.includes(TAG_QUESTION)) {
			params.tags = value.split('=')[1];
		} else if (value.includes(AND_SOURCE) || value.includes(SOURCE_QUESTION)) {
			params.source = value.split('=')[1];
		}
	});

	return params;
})();

export const getLinkWithTags = link => {
	if (!avatureTags) return link;
	const doesContainParameters = link.includes('?');
	let source = '';
	let tags = '';

	if (!!avatureTags.source)
		source = `${doesContainParameters ? AND_SOURCE : SOURCE_QUESTION}${
			avatureTags.source
		}`;

	if (!!avatureTags.tags)
		tags = `${
			doesContainParameters || !!avatureTags.source ? AND_TAG : TAG_QUESTION
		}${avatureTags.tags}`;

	if (!sessionStorage.getItem('params')) {
		sessionStorage.setItem('params', `${source}${tags}`);
	}

	return `${link}${source}${tags}`;
};
//Appending page url params to the element link params
export const getElementLinkWithTags = link => {
	if (!avatureTags) return link;
	let combinedTags;
	const pageUrl = new URL(window.location.href);
	const elementUrl = new URL(link, 'https://www.pmi.com');

	const params = sessionStorage.getItem('params');
	const sessionSource = params.split('tags')[0].split('?')[1];
	const storageTags = sessionStorage
		.getItem('params')
		.split('tags=')[1]
		.split('|');

	if (pageUrl.searchParams.get('tags')) {
		const pageUrlTags = pageUrl.searchParams.get('tags').split('|');
		combinedTags = [...new Set([...storageTags, ...pageUrlTags])].join('|');
	} else {
		combinedTags = storageTags.join('|');
	}

	const tagsToAppend = `${combinedTags}${
		elementUrl.searchParams.get('tags')
			? `|${elementUrl.searchParams.get('tags')}`
			: ''
	}`;

	const arrayTags = [...new Set(tagsToAppend.split('|'))];
	const uniqueTags = arrayTags.join('|');

	if (link.includes('source')) {
		if (!link.includes(sessionSource)) {
			const currentSource = link.split(AND_TAG)[0];
			const newSource = currentSource
				.split(SOURCE_NAME)[0]
				.concat(sessionSource.split('&')[0]);

			return `${newSource}${uniqueTags ? `${AND_TAG}${uniqueTags}` : ''}`;
		}

		return `${link.split(AND_TAG)[0]}${
			uniqueTags ? `${AND_TAG}${uniqueTags}` : ''
		}`;
	} else {
		let tagsWithSource;

		if (sessionStorage.getItem('params')) {
			tagsWithSource = sessionSource.concat(`${'tags='}${uniqueTags}`);
		} else {
			tagsWithSource = `${PMI_SOURCE}`.concat(`${'tags='}${uniqueTags}`);
		}

		return `${link.split('tags=')[0]}${uniqueTags ? tagsWithSource : ''}`;
	}
};
export const initAvatureTracking = () => {
	if (!avatureTags) return;
	const linkElements = bodyElement.querySelectorAll('[href]');
	linkElements.forEach(element => {
		const href = element.getAttribute('href');
		let newLink;
		if (linkHasParams(href)) {
			newLink = getElementLinkWithTags(href);
		} else {
			newLink = getLinkWithTags(href);
		}

		element.setAttribute('href', newLink);
	});
};
