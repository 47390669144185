const {
	emitter,
	EVENT_MARKET_REDIRECT_CLOSE,
	EVENT_MARKET_REDIRECT_INIT,
	EVENT_MAIN_NAV_OPEN,
	EVENT_MAIN_NAV_CLOSE,
	EVENT_MAIN_NAV_FOLDING,
	EVENT_MAIN_NAV_UNFOLDING,
	DELAY_THROTTLE,
	bodyElement,
} = require('util/consts');
const {
	trackGtm,
	fillWithDomElements,
	fillWithDomElementsAll,
	onControllerReady,
	freezeScroll,
	unfreezeScroll,
	awaitNextFrame,
	awaitEventOn,
} = require('util/functions');
const asyncImport = require('util/asyncImport').default;

const CLASS_MOBILE_NAV_BURGER = 'mobile-nav--burger';
const CLASS_IS_NAVIGATION = 'is-navigation';
const CLASS_MOBILE_NAV_PANELS = 'mobile-nav--panel';
const CLASS_MOBILE_NAV_OPENER = 'mobile-nav--opener';
const CLASS_MARKET_SEARCHER = 'market-search-menu';
const CLASS_LOADING_OVERLAY = 'loading-overlay';

const CLASS_SCROLLED = 'scrolled';
const CLASS_ACTIVE = 'active';
const CLASS_OPEN = 'open';
const CLASS_CLOSE = 'close';

const TARGET_MARKET = 'markets';

const selectors = {
	burger: CLASS_MOBILE_NAV_BURGER,
	loadingOverlay: CLASS_LOADING_OVERLAY,
	mobileNavOpener: CLASS_MOBILE_NAV_OPENER,
};
const selectorsAll = {
	navButtons: CLASS_IS_NAVIGATION,
	navPanels: CLASS_MOBILE_NAV_PANELS,
};

module.exports = {
	async init() {
		this.pageMain = document.querySelector('.page-main');
		fillWithDomElements(this, selectors);
		if (this.burger) {
			fillWithDomElementsAll(this, selectorsAll);
			this.isMobileNavOpen = false;
			this.navOpen = true;
			this.scroll = window.pageYOffset;
			this.modal = false;
			this.burger.addEventListener('click', this.onBurgerClick.bind(this));
			this.burgerOriginalLabel = this.burger.getAttribute('aria-label');
			this.burgerOriginalTarget = this.burger.getAttribute('aria-controls');
			this.setMobileNavArias(false);
		}
		this.listenToMarketRedirect();
		this.tracking();
		this.initEmitters();
		this.initPanels();
		await asyncImport(this, 'throttle');
		window.addEventListener(
			'scroll',
			this.throttle(this.onScroll.bind(this), DELAY_THROTTLE),
		);
		this.marketSearcher = this.root.querySelector(
			`.${CLASS_MOBILE_NAV_PANELS} .${CLASS_MARKET_SEARCHER}`,
		);
		this.marketController =
			this.marketSearcher && (await onControllerReady(this.marketSearcher));
		this.root.addEventListener('focusin', () => {
			if (this.navOpen) {
				this.navOpen = false;
				this.update();
			}
		});
	},
	initEmitters() {
		emitter.on(EVENT_MAIN_NAV_OPEN, ({ closeCallback, target, label }) => {
			this.modalCallback = closeCallback;
			this.burger && this.burger.classList.add(CLASS_CLOSE);
			this.setBurgerArias(label, true, target);
			this.modal = true;
		});
		emitter.on(EVENT_MAIN_NAV_CLOSE, () => {
			this.modalCallback = null;
			this.burger && this.burger.classList.remove(CLASS_CLOSE);
			this.modal = false;
			this.setMobileNavArias(false);
		});
	},
	setBurgerArias(label, expanded, controls) {
		[
			['label', label],
			['expanded', expanded],
			['controls', controls],
		].forEach(([aria, value]) =>
			this.burger.setAttribute(`aria-${aria}`, value),
		);
	},
	initPanels() {
		this.panels = {};
		this.navPanels &&
			this.navPanels.forEach(panel => {
				const { name } = panel.dataset;
				this.panels[name] = panel;
			});
		this.navButtons &&
			this.navButtons.forEach(button => {
				const { target } = button.dataset;
				button.addEventListener('click', this.goToPanel.bind(this, target));
			});
	},
	goToPanel(panelName) {
		Object.keys(this.panels).forEach(name => {
			const panel = this.panels[name];
			panel.classList.toggle(CLASS_ACTIVE, name === panelName);
		});
		if (panelName === TARGET_MARKET) this.activateMarket();
	},
	listenToMarketRedirect() {
		emitter.on(EVENT_MARKET_REDIRECT_INIT, ({ className }) => {
			this.root.classList.add(className);
			this.pageMain.classList.add(className);
		});
		emitter.on(EVENT_MARKET_REDIRECT_CLOSE, ({ className }) => {
			this.root.classList.remove(className);
			this.pageMain.classList.remove(className);
		});
	},
	onBurgerClick() {
		if (this.modal) {
			this.modalCallback();
			this.burger.classList.remove(CLASS_CLOSE);
			this.modal = false;
			this.modalCallback = null;
		} else {
			this[this.isMobileNavOpen ? 'closeMobileNav' : 'openMobileNav']();
		}
	},
	onScroll() {
		const bottomLimit =
			document.documentElement.offsetHeight - window.innerHeight - 100;
		if (
			(window.pageYOffset > this.scroll &&
				window.pageYOffset >= 10 &&
				!this.modal) ||
			window.pageYOffset > bottomLimit
		) {
			this.navOpen = true;
		} else {
			this.navOpen = false;
		}
		this.update();
		this.scroll = window.pageYOffset;
	},
	async openMobileNav() {
		if (this.isMobileNavOpen) return;
		this.mobileNavOpener.removeAttribute('hidden');
		await awaitNextFrame();
		this.root.classList.add(CLASS_OPEN);
		freezeScroll();
		this.isMobileNavOpen = true;
		this.setMobileNavArias(true);
	},
	async closeMobileNav() {
		if (!this.isMobileNavOpen) return;
		this.root.classList.remove(CLASS_OPEN);
		unfreezeScroll();
		await awaitEventOn(this.mobileNavOpener, 'transitionend');
		this.mobileNavOpener.setAttribute('hidden', '');
		this.isMobileNavOpen = false;
		this.setMobileNavArias(false);
	},
	setMobileNavArias(isOpen) {
		this.setBurgerArias(
			this.burgerOriginalLabel,
			isOpen,
			this.burgerOriginalTarget,
		);
	},
	update() {
		const pageMain = document.querySelector('.page-main');
		const shouldAddClass = pageMain
			? pageMain.offsetHeight > window.innerHeight + 500
			: true;

		if (this.navOpen && !this.isMobileNavOpen && shouldAddClass) {
			bodyElement.classList.add(CLASS_SCROLLED);
			emitter.emit(EVENT_MAIN_NAV_FOLDING);
		} else {
			bodyElement.classList.remove(CLASS_SCROLLED);
			emitter.emit(EVENT_MAIN_NAV_UNFOLDING);
		}
	},
	activateMarket() {
		this.marketController && this.marketController.fetchCountries();
	},
	tracking() {
		this.home = this.root.querySelector('.main-nav--logo');
		this.navElements = [
			...this.root.querySelectorAll('.main-nav--element a'),
			...this.root.querySelectorAll('.mobile-nav--panel a'),
		];
		this.dropDownElements = [
			...this.root.querySelectorAll('.drop-down--content a'),
			...this.root.querySelectorAll('.mobile-nav--indent .is-low a'),
		];
		this.world = this.root.querySelector('.world-widget');
		this.basicNavGtmObject = {
			eventCategory: 'main-navigation',
			eventAction: 'click',
		};

		this.home?.addEventListener('click', () => {
			trackGtm({
				...this.basicNavGtmObject,
				eventLabel: 'home',
			});
		});

		this.navElements?.forEach(element => {
			element.addEventListener('click', () => {
				trackGtm({
					...this.basicNavGtmObject,
					eventLabel: element.innerHTML,
				});
			});
		});

		this.dropDownElements?.forEach(element => {
			element.addEventListener('click', () => {
				trackGtm({
					eventCategory: 'business-navigation',
					eventAction: 'click',
					eventLabel: element.innerHTML,
				});
			});
		});

		this.world?.addEventListener('click', () => {
			trackGtm({
				eventCategory: 'business-navigation',
				eventAction: 'click',
				eventLabel: 'markets',
			});
		});
	},
};
