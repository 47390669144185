const {
	fillWithDomElements,
	onControllerReady,
	toggleMenuArias,
} = require('util/functions');
const {
	emitter,
	EVENT_MAIN_NAV_FOLDING,
	EVENT_MAIN_NAV_FOCUS,
} = require('util/consts');
const {
	outClickSubscribe,
	outClickUnSubscribe,
} = require('util/outClickSubscriber');

const CLASS_MAP_ICON = 'world-widget--link';
const CLASS_DROP = 'world-widget--drop';
const CLASS_FOLD_BUTTON = 'world-widget--fold-button';
const CLASS_SEARCHER = 'market-search-menu';
const CLASS_DROP_CONTAINER = 'world-widget--drop-container';
const CLASS_WORLD_DROPDOWN = 'dropdown-menu-widget--world-dropdown';

const CLASS_OPEN = 'open';
const CLASS_ACTIVE = 'active';

const selectors = {
	mapIcon: CLASS_MAP_ICON,
	drop: CLASS_DROP,
	dropButton: CLASS_FOLD_BUTTON,
	searcher: CLASS_SEARCHER,
	container: CLASS_DROP_CONTAINER,
	worldDropdown: CLASS_WORLD_DROPDOWN,
};

module.exports = {
	async init() {
		fillWithDomElements(this, selectors);
		this.opened = false;
		this.worldOpen = false;
		this.input = this.searcher.querySelector('input');
		this.countriesApi = this.root.dataset.countriesApi;
		this.searcherController = await onControllerReady(this.searcher);
		this.searcherController.onCountryLoad = this.adaptHeight.bind(this);
		this.worldDropdown = document.querySelector(
			'.dropdown-menu-widget--world-dropdown',
		);

		this.initEvents();
		this.updateArias();
	},
	initEvents() {
		this.mapIcon.addEventListener('mouseover', this.open.bind(this));
		this.mapIcon.addEventListener('focusin', this.open.bind(this));
		this.dropButton.addEventListener('click', () =>
			this[this.opened ? 'close' : 'open'](),
		);
		document.addEventListener('click', ({ target }) => {
			if (!this.root.contains(target)) this.close();
		});
		emitter.on(EVENT_MAIN_NAV_FOLDING, this.close.bind(this));
		emitter.on(EVENT_MAIN_NAV_FOCUS, this.close.bind(this));
		this.container.addEventListener('keydown', e => {
			if (e.keyCode === 27) this.close();
		});
		this.input.addEventListener('keyup', e => {
			if (e.keyCode === 27) this.close();
		});
		if (this.worldDropdown) {
			this.searcherController.linkAll.addEventListener(
				'click',
				this.toggleWorldDropdown.bind(this, true),
			);
			this.worldDropdown.addEventListener(
				'mouseenter',
				this.toggleWorldDropdown.bind(this, true),
			);
			this.worldDropdown.addEventListener(
				'mouseleave',
				this.toggleWorldDropdown.bind(this, false),
			);
			document.addEventListener('click', e => {
				if (
					this.worldOpen &&
					e.target !== this.worldDropdown &&
					e.target !== this.searcherController.linkAll &&
					!this.worldDropdown.contains(e.target)
				) {
					this.worldDropdown.style.height = '0px';
					this.worldDropdown.classList.remove(CLASS_ACTIVE);
					this.worldOpen = false;
				}
			});
			this.worldDropdown.addEventListener('keyup', e => {
				if (e.keyCode === 27) {
					this.toggleWorldDropdown(false, e);
				}
			});
		}
	},
	toggleWorldDropdown(isActive, e) {
		e.stopPropagation();
		const container = this.worldDropdown.querySelector('.country-info');
		const height = container.offsetHeight;
		this.worldDropdown.style.height = `${isActive ? height : 0}px`;
		this.worldDropdown.classList.toggle(CLASS_ACTIVE, isActive);
		this.worldOpen = isActive;
		this.opened = true;
		if (isActive) {
			const continents = this.worldDropdown.querySelectorAll(
				'.country-info--continent',
			);
			setTimeout(() => {
				continents.forEach(element => {
					element.classList.remove(CLASS_ACTIVE);
				});
				continents[0].click();
				continents[0].focus();
			}, 100);
		}
		this.close();
	},
	async open() {
		if (this.opened) return;
		this.mapIcon.classList.add(CLASS_ACTIVE);
		const focusEvent = () => {
			this.input.focus();
			this.container.removeEventListener('transitionend', focusEvent);
		};
		this.container.addEventListener('transitionend', focusEvent);
		this.root.classList.add(CLASS_OPEN);
		this.adaptHeight();
		outClickSubscribe(this.root, this.close.bind(this));
		this.opened = true;
		await this.searcherController.fetchCountries();
		this.updateArias();
	},
	close() {
		if (!this.opened) return;
		this.mapIcon.classList.remove(CLASS_ACTIVE);
		this.root.classList.remove(CLASS_OPEN);
		this.input.blur();
		this.input.value = '';
		this.adaptHeight(true);
		this.opened = false;
		outClickUnSubscribe(this.root);
		this.updateArias();
	},
	getLinks() {
		const { linkAll, dropController } = this.searcherController;
		const { suggestions, input } = dropController;
		return [
			...(suggestions.querySelectorAll('a') || []),
			suggestions,
			linkAll,
			input,
		];
	},
	updateArias() {
		toggleMenuArias(this.mapIcon, this.getLinks() || [], this.opened);
	},
	adaptHeight(close) {
		const height = close ? 0 : this.searcher.offsetHeight;
		this.container.style.height = height + 'px';
	},
};
