import 'lazysizes';
import { initAvatureTracking } from './avatureTracking.js';
import formsInit from './forms.js';
import initRecaptcha from './initRecaptcha.js';
import { initMainMarginTop } from './setMainMarginTop.js';

const mainInit = () => {
	/* global localStorage, PRODUCTION */
	if (!PRODUCTION && typeof localStorage !== 'undefined')
		localStorage.debug = 'app,GTM';

	const controllers = require('./controllers');
	const initializer = require('./controllers-init');

	window.dataLayer = window.dataLayer || [];
	setTimeout(async () => {
		await initializer(document, controllers);
		initRecaptcha();
		formsInit();
	}, 0);

	const links = document.querySelectorAll('[data-href]');
	Array.from(links).forEach(el => {
		const link = el.dataset.href;
		el.addEventListener('click', e => {
			e.preventDefault();
			e.stopPropagation();
			window.location.href = link;
		});
	});
	initMainMarginTop();
	initAvatureTracking();
	//Translate the close label
	const closeLabel = {
		cs: 'Zavřít',
		iw: 'סגור',
		he: 'סגור',
		hu: 'Bezárás',
		ja: '選ぶ',
		kk: 'Жабық',
		ko: '닫기',
		it: 'Uždaryti',
		lv: 'Aizvērt',
		ro: 'Închide',
		ru: 'Закрывать',
		sk: 'Zavrieť',
		sl: 'Zapri',
		sr: 'Затвори',
		th: 'ปิด I',
		tr: 'Kapat',
		uk: 'Закрити',
		vi: 'Đóng',
		zh: '关',
		en: 'Close',
		ar: 'أغلق',
	}[document.documentElement.lang];

	document.body.dataset.closeLabel = closeLabel;
};
export default mainInit;
