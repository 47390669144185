const { BASIC_GTM, CLASS_NO_SCROLL, bodyElement } = require('./consts');
const {
	default: { breakMediumSmall },
} = require('util/sass-variables');

const FOCUSABLES = ['a', 'button', 'iframe'];

/**
 * Extract a number from a unit expressed value (ex: 1280px => 1280)
 * @param {string} value
 * @returns the number
 */
export function getNumber(value) {
	return parseFloat(value.replace(/^\D+/g, ''));
}

/**
 * Return true if the window is at list of a certain width (with unit)
 * @param {string} query
 * @returns a boolean
 */
export function mediaQueryMin(query) {
	return window.matchMedia(`(min-width: ${query})`).matches;
}
export function mediaQueryMax(query) {
	return window.matchMedia(`(max-width: ${query})`).matches;
}

export function getClosest(array, compareValue) {
	let closestGap;
	let closestValue;
	array.find(value => {
		const diff = Math.abs(value - compareValue);
		if (diff < closestGap || !closestGap) {
			closestGap = diff;
			closestValue = value;
		}
		if (diff === 0) return true;
		return false;
	});
	return closestValue;
}

export function trackGtm(trackData) {
	window.dataLayer.push({
		...BASIC_GTM,
		...trackData,
	});
}
export function trackGtm4(trackData) {
	window.dataLayer.push({
		...trackData,
	});
}
export const trackOutgoing = kind => (title, link) => {
	trackGtm({
		eventCategory: 'outgoing tracking',
		eventAction: kind,
		eventLabel: `${title} / ${link}`,
	});
};
export const trackNavigation = (eventAction, eventLabel) => {
	trackGtm({
		eventCategory: 'content-navigation',
		eventAction,
		eventLabel,
	});
};
export const trackDownload = trackOutgoing('download');
export const trackLink = trackOutgoing('outgoing link');

export const trackSeeMore = (title, componentName, isUnfolding) =>
	trackNavigation(
		`show ${isUnfolding ? 'more' : 'less'}`,
		`${componentName} / ${title}`,
	);
export const trackPreviousNext = (title, label, isNext) =>
	trackNavigation(`${isNext ? 'next' : 'previous'} | ${title}`, label);
export const trackNavigationDot = (title, number) =>
	trackNavigation(`dot | ${title}`, number);

export const onControllerReady = element =>
	new Promise(resolve => {
		if (element.__controller) {
			resolve(element.__controller);
		} else {
			element.__onInitialized = () => resolve(element.__controller);
		}
	});
export function initControllerLink(self) {
	self.root.__controller = self;
	if (self.root.__onInitialized) self.root.__onInitialized();
}
export function onAllControllerReady(elements, callback) {
	let left = elements.length;
	function onInit() {
		left--;
		if (left === 0) callback();
	}
	elements.forEach(element => {
		if (element.__controller) {
			onInit();
		} else {
			element.__onInitialized = onInit;
		}
	});
}
export const awaitControllersReady = elements =>
	new Promise(resolve => onAllControllerReady(elements, resolve));

function fillWithDomElementsGeneric(self, selectors, all) {
	Object.keys(selectors).forEach(
		key =>
			(self[key] = self.root['querySelector' + (all ? 'All' : '')](
				'.' + selectors[key],
			)),
	);
}
//wait for element to appear in the DOM
export function waitForElmToLoad(selector) {
	return new Promise(resolve => {
		if (document.querySelector(selector)) {
			return resolve(document.querySelector(selector));
		}

		const observer = new MutationObserver(mutations => {
			if (document.querySelector(selector)) {
				resolve(document.querySelector(selector));
				observer.disconnect();
			}
		});

		observer.observe(document.body, {
			childList: true,
			subtree: true,
		});
	});
}

export function fillWithDomElements(self, selectors) {
	fillWithDomElementsGeneric(self, selectors, false);
}

export function fillWithDomElementsAll(self, selectors) {
	fillWithDomElementsGeneric(self, selectors, true);
}

// use only when having a lot of events
export function addEventsTo(target, events) {
	Object.keys(events).forEach(event =>
		target.addEventListener(event, events[event]),
	);
}

export function setClass(element, isAdding, className) {
	element.classList.toggle(className, isAdding);
}

export function setClassAll(elements, isAdding, className) {
	elements.forEach(el => setClass(el, isAdding, className));
}

export function twoCiphers(number) {
	const string = number.toString();
	if (string.length < 2) return `0${string}`;
	return string;
}

export const awaitEventOn = (element, event) =>
	new Promise(resolve =>
		element.addEventListener(event, resolve, {
			once: true,
		}),
	);
export const awaitEventOnCondition = (element, event, filterFn) =>
	new Promise(resolve => {
		const callback = e => {
			if (filterFn(e)) {
				element.removeEventListener(event, callback);
				resolve();
			}
		};
		element.addEventListener(event, callback);
	});

export const awaitNextFrame = () =>
	new Promise(resolve => requestAnimationFrame(resolve));

export const arrayize = input => (Array.isArray(input) ? input : [input]);

export const setHeightFrom = (element, reference) => {
	const height = arrayize(reference).reduce(
		(total, currentReference) =>
			total + currentReference.offsetHeight || currentReference.innerHeight,
		0,
	);
	element.style.height = height + 'px';
};

export const awaitSetHeightFrom = (element, reference) =>
	new Promise(resolve => {
		setHeightFrom(element, reference);
		element.addEventListener('transitionend', resolve, { once: true });
	});

function setScrollFreeze(isFrozen) {
	bodyElement.classList.toggle(CLASS_NO_SCROLL, isFrozen);
}
export function freezeScroll(onlyOnMobile) {
	if (!onlyOnMobile || !mediaQueryMin(breakMediumSmall)) setScrollFreeze(true);
}
export function unfreezeScroll() {
	setScrollFreeze(false);
}

export const toggleMenuArias = (button, focusables, isOpen) => {
	focusables.forEach(focusable =>
		focusable.setAttribute('tabindex', isOpen ? 0 : -1),
	);
	button.setAttribute('aria-expanded', isOpen);
};

export const isFocusable = element =>
	element &&
	FOCUSABLES.includes(element.tagName.toLowerCase()) &&
	!element.disabled &&
	element.getAttribute('tabindex') !== '-1';

export const getFocusablesFrom = element =>
	[...element.querySelectorAll(FOCUSABLES.join(','))].filter(element =>
		isFocusable(element),
	);

export const injectFocusablesList = element => {
	element.__focusables = getFocusablesFrom(element);
};

export const showOnDigits = (number, digits) => {
	const ciphers = Number(number).toString();
	const ciphersLenght = ciphers.length;
	const missing = digits - ciphersLenght;
	const preCiphers = missing > 0 ? new Array(missing).fill('0').join('') : '';
	return preCiphers + ciphers;
};

export const secondsToTimer = timing => {
	const [minutes, seconds] = [timing / 60, timing % 60].map(val =>
		showOnDigits(Math.floor(val), 2),
	);
	return `${minutes}:${seconds}`;
};
export const clamp = (val, min, max) => Math.max(Math.min(val, max), min);

//Show/Hide element if it's in viewport
export const isElementInViewport = (options, element, className) => {
	const callback = function (entries) {
		entries.forEach(entry => {
			const childElements = entry.target.children;
			let time = 0;
			[...childElements].forEach(element => {
				if (entry.isIntersecting) {
					time = time + 500;
					setTimeout(() => {
						entry.target.classList.remove(className);
						element.classList.remove(className);
					}, time);
				} else {
					entry.target.classList.add(className);
					element.classList.add(className);
				}
			});
		});
	};

	const observer = new IntersectionObserver(callback, options);

	observer.observe(element);
};

//Function for disable static url download: duration (seconds) - default and custom & threshold (seconds) - to sync with CSS animation duration
export function disableStaticURL(
	target,
	disableClass,
	duration = 2,
	threshold = 2,
) {
	target.classList.add(disableClass);
	target.style.setProperty('--animation-duration', `${duration + threshold}s`);
	setTimeout(function () {
		target.classList.remove(disableClass);
		target.style.removeProperty('--animation-duration');
	}, duration * 1000);
}
