const { default: UnfoldableList } = require('util/UnfoldableSet');
const { trackGtm, trackGtm4 } = require('../../../../js/functions');

module.exports = {
	init() {
		const menuTitles = this.root.querySelectorAll('.unfoldable-menu--title');
		const subMenuItems = this.root.querySelectorAll('.unfoldable-menu--link');
		const marketNavLogo = this.root.querySelector('.market-nav--logo');
		const goToPMI = this.root.querySelector('.market-nav--goto');

		goToPMI.addEventListener('click', () => {
			trackGtm({
				eventCategory: 'market-navigation',
				eventAction: 'click',
				eventLabel: 'Go To PMI.COM',
			});
			trackGtm4({
				event: 'GA4_event_c_market_navigation',
				widget_name: 'V3-Market-nav-menu',
				link_url: window.location.host,
				link_text: 'Go To PMI.COM',
				level: 'Level 1',
			});
		});

		marketNavLogo.addEventListener('click', () => {
			trackGtm({
				eventCategory: 'market-navigation',
				eventAction: 'click',
				eventLabel: 'logo',
			});
			trackGtm4({
				event: 'GA4_event_c_market_navigation',
				widget_name: 'V3-Market-nav-menu',
				link_url: marketNavLogo.href,
				link_text: 'Home',
				level: 'Level 1',
			});
		});

		menuTitles.forEach(element => {
			element.addEventListener('click', () => {
				const url = element.hasAttribute('href')
					? element.href
					: element.parentNode.href;
				trackGtm({
					eventCategory: 'market-navigation',
					eventAction: 'click',
					eventLabel: element.innerText,
				});
				if (element.parentNode.tagName != 'BUTTON') {
					trackGtm4({
						event: 'GA4_event_c_market_navigation',
						widget_name: 'V3-Market-navigation-with-menu',
						link_url: url,
						link_text: element.innerText,
						level: 'Level 1',
					});
				}
			});
		});

		subMenuItems.forEach(element => {
			element.addEventListener('click', () => {
				const subMenuContainer = element.closest(
					'.unfoldable-menu--container',
				).previousSibling;
				trackGtm({
					eventCategory: 'market-navigation',
					eventAction: subMenuContainer.previousSibling.innerText,
					eventLabel: element.innerText,
				});
				trackGtm4({
					event: 'GA4_event_c_market_navigation',
					widget_name: 'V3-Market-navigation-with-menu',
					link_url: element.href,
					link_text: element.innerText,
					level: 'Level 2',
				});
			});
		});

		new UnfoldableList(this.root, {
			unfoldButton: 'market-nav--burger',
			container: 'market-nav--menus-container',
			menus: 'market-nav--menus',
		});
	},
};
