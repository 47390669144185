import NanoEvents from 'nanoevents';

export const MAIN_EASING = 'cubicBezier(0.42, 0, 0.58, 1)';
export const OUT_EASING = 'cubicBezier(0, 0, 0.58, 1)';
export const BASIC_GTM = {
	event: 'GA Tracking',
};
export const DELAY_DEBOUNCE = 200;
export const DELAY_DEBOUNCE_LONG = 500;
export const DELAY_THROTTLE = 100;

// Classes
export const CLASS_NO_SCROLL = 'no-scroll';
export const CLASS_NO_DISPLAY = 'no-display';

// Elements
export const bodyElement = document.querySelector('body');

// Events
export const emitter = new NanoEvents();
export const EVENT_MARKET_REDIRECT_INIT = 'marketRedirect.init';
export const EVENT_MARKET_REDIRECT_CLOSE = 'marketRedirect.close';
export const EVENT_MAIN_NAV_FOLDING = 'mainNav.folding';
export const EVENT_MAIN_NAV_UNFOLDING = 'mainNav.unFolding';
export const EVENT_MAIN_NAV_OPEN = 'mainNav.modalOpen';
export const EVENT_MAIN_NAV_CLOSE = 'mainNav.modalClose';
export const EVENT_MAIN_NAV_FOCUS = 'mainNav.focus';

// Hammer
export const DIRECTION_NONE = 1;
export const DIRECTION_LEFT = 2;
export const DIRECTION_RIGHT = 4;
export const DIRECTION_UP = 8;
export const DIRECTION_DOWN = 16;
export const DIRECTION_HORIZONTAL = 6;
export const DIRECTION_VERTICAL = 24;
export const DIRECTION_ALL = 30;
