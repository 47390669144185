import { bodyElement } from './consts';

const RECAPTCHA_INIT_CALLBACK = '__multipleReCAPTCHAOnReady';

const initRecaptchaScript = initFn => {
	window[RECAPTCHA_INIT_CALLBACK] = initFn;
	const scriptElement = document.createElement('script');
	scriptElement.src = `https://www.google.com/recaptcha/api.js?onload=${RECAPTCHA_INIT_CALLBACK}&render=explicit`;
	bodyElement.appendChild(scriptElement);
};

const initRecaptcha = () => {
	const captchaList = document.querySelectorAll('[data-recaptcha-key]');
	const init = () => {
		captchaList.forEach(captchaElement => {
			const { recaptchaKey } = captchaElement.dataset;
			window.grecaptcha.render(captchaElement, {
				sitekey: recaptchaKey,
				theme: 'light',
				...(captchaElement.__callback
					? {
							callback: captchaElement.__callback,
							'expired-callback': captchaElement.__expiredCallback,
					  }
					: {}),
			});
		});
	};
	if (captchaList.length) {
		initRecaptchaScript(init);
	}
};
export default initRecaptcha;
