const CONTROLLERS_INIT_MAX_SPAN = 20;

function manualSort(a, b) {
	const controllerA = a.dataset.controller;
	const controllerB = b.dataset.controller;
	const controllerMarketRedirect = 'pmi:market-redirect-widget';
	const controllerMainNav = 'pmi:main-nav';
	if (
		controllerA === controllerMarketRedirect &&
		controllerB === controllerMainNav
	) {
		return 1;
	} else if (
		controllerA === controllerMainNav &&
		controllerB === controllerMarketRedirect
	) {
		return -1;
	} else {
		return 0;
	}
}
const initialize = (elements, controllers) =>
	new Promise(resolve => {
		let controllersLeftToInitCount = elements.length;
		let controllersReadyToInitialize = [];
		let continueNextFrame = false;
		let startTime = window.performance.now();

		// triggers the fact that the initialisationk continues next frame
		// triggers the requestAnimationFrame only once
		function doContinueNextFrame() {
			if (!continueNextFrame && controllersLeftToInitCount) {
				continueNextFrame = true;
				requestAnimationFrame(() => {
					continueNextFrame = false;
					startTime = window.performance.now();
					initControllers();
				});
			}
		}
		// init all the loaded controllers available in a CONTROLLERS_INIT_MX_SPAN span
		function initControllers() {
			while (
				controllersReadyToInitialize.length > 0 &&
				startTime + CONTROLLERS_INIT_MAX_SPAN > window.performance.now()
			) {
				const { Controller, element, controllerName } =
					controllersReadyToInitialize.shift();
				const instance =
					typeof Controller === 'function'
						? new Controller()
						: Object.create(Controller);
				instance.root = element;
				instance.componentName = controllerName;

				if (instance.init) instance.init(element);
				controllersLeftToInitCount--;
			}
			if (!controllersLeftToInitCount) resolve();
			else doContinueNextFrame();
		}
		// load all the js files and setup the contollers
		elements.forEach(element => {
			const controllerName = element.dataset.controller;
			const addController = Controller => {
				controllersReadyToInitialize.push({
					element,
					Controller,
					controllerName,
				});
				doContinueNextFrame();
			};
			const controllerObj = controllers[controllerName];
			if (typeof controllerObj === 'function')
				controllerObj().then(addController);
			else addController(controllerObj);
		});
	});
async function initializeControllers(root, controllers) {
	const domElements = Array.from(
		root.querySelectorAll('[data-controller]'),
	).sort(manualSort);
	await initialize(domElements, controllers);
}

module.exports = initializeControllers;
