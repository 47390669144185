const subscriptions = [];

const outClickEvent = e => {
	const { target } = e;
	subscriptions.forEach(([root, callback]) => {
		const isTargetInRoot = root.contains(target) || root === target;
		if (!isTargetInRoot) callback(e);
	});
};

const toggleListener = doesActivate => {
	const listenerFn = doesActivate ? 'addEventListener' : 'removeEventListener';
	['click', 'focusin'].forEach(eventName =>
		document[listenerFn](eventName, outClickEvent),
	);
};

export const outClickSubscribe = (root, callback) => {
	outClickEvent({ target: root });

	if (!subscriptions.length) toggleListener(true);
	subscriptions.push([root, callback]);
};

export const outClickUnSubscribe = root => {
	if (!subscriptions.length) return;
	const rootIndex = subscriptions.findIndex(
		([currentRoot]) => currentRoot === root,
	);
	if (rootIndex === -1) return;
	subscriptions.splice(rootIndex, 1);
	if (!subscriptions.length) toggleListener(false);
};

export const triggerOtherClose = () => outClickEvent({ target: null });
